import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    meta: {
      title: '请登录 |IML俄罗斯海外仓|艾姆勒|俄罗斯海外仓|中俄快递业务|中俄跨境供应链解决方案供应商'
    }
  },
  {
    path: '/main',
    name: 'Main',
    redirect: "/main/mine",
    component: () => import(/* webpackChunkName: "main" */ '../views/Main.vue'),
    children: [
      {
        path: 'trail',
        name: 'trail',
        component: () => import(/* webpackChunkName: "main" */ '../views/Home.vue')
      }, {
        path: 'report',
        name: 'report',
        component: () => import(/* webpackChunkName: "report" */ '../views/Report.vue')
      }, {
        path: 'clue',
        name: 'clue',
        component: () => import(/* webpackChunkName: "clue" */ '../views/Clue.vue')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
