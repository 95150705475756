<template>
  <div class="bg">
    <div class="login-div">
      <div class="logo-div" style="text-align:center;">
        <span class="txt">
          <img  src="../../public/images/logo.png" style="height:32px;" /> 艾姆勒物流管理系统
        </span>
      </div>
      <div class="form-div">
        <a-input v-model="user.userName"  placeholder="用户名" size="large" >
          <a-icon slot="prefix" type="user" />
        </a-input>
        <br/>
        <a-input-password v-model="user.pwd" placeholder="密码"  @keyup.enter="login" size="large" >
          <a-icon slot="prefix" type="lock" />
        </a-input-password>
        <br/><br/>
        <a-button type="primary" block size="large" :loading="loginning" :disabled="loginning" @click="login">登录</a-button>
        <div style="width:100%;text-align:center;padding:28px 8px;line-height:32px;font-size:15px;">
          <!-- 技术支持: 909396582@qq.com -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import axios from 'axios'

export default {
  components: {
  },
  data: function(){
    return {
      loginning:false,
      user: {
        userName: "",
        pwd: ""
      }
    };
  },
  methods:{

    login(){
      this.loginning = true;
      let pwdMd5 = this.$md5(this.user.pwd);
      this.$axios.post("/login", {userName:this.user.userName, pwd:pwdMd5}).then(resp=>{
        if(resp.data && resp.data["code"] == 200){
          Vue.prototype.currentUser = resp.data.data;
          axios.defaults.headers.token = resp.data.data.token;
          window.sessionStorage.setItem("token",resp.data.data.token)
          window.location.hash = "#/main/trail";
        }else{
          this.loginning = false;
          this.$notification.error({
            message: '登录失败',
            description:
              resp.data.msg
          });
        }
      });
    }

  }
}
</script>

<style scoped>
.bg{
  background: no-repeat left center url("../../public/images/bg1.jpg");
  background-color: rgb(19, 144, 224);
  width: 100%;
  height: 100%;
  background-size: contain;
}
.login-div{
  width: 400px;
  height: 350px;
  background:  rgba(255, 255, 255, 0.8);
  /* border: 5px solid rgba(99, 99, 99, 0.3); */
  border-radius: 20px;
  float: right;
  margin:130px 50px auto auto;
}
.logo-div{
  text-align:left;
  padding: 25px 20px;
}
.logo-div .txt{
  line-height: 40px;
  font-size: 15pt;
  color: #000;
}
.form-div{
  padding: 5px 50px;
}
.form-div span{
  margin-top: 10px;
}
</style>
